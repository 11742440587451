import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import { getMediaQueryByPageType } from 'common/constants/mediaQueries'
import GlobalStyles from 'common/utils/GlobalStyles'
import AffiliateBadge from 'publisher/components/AffiliateBadge'
import pageSelectors from 'publisher/store/page/pageSelectors'
import useManagement, {
  selectors as managementSelectors,
} from '../hooks/useManagement'
import usePage from '../hooks/usePage'
import Notification from './core/Notification'
import PreviewBanner from './core/PreviewBanner'

function Page({ createEntityElement, createPage }) {
  const rootEntity = usePage(pageSelectors.getRootEntity)
  const pageType = usePage(pageSelectors.getPageType)
  const seo = usePage(pageSelectors.getSeo)
  const isFreemiumUser = useManagement(managementSelectors.isFreemiumUser)
  const isMobile = useManagement(managementSelectors.isMobile)
  const currentUrl = useManagement(managementSelectors.getUrl)
  const PageType = createPage(pageType)
  const showAffiliateBadge = isFreemiumUser || rootEntity.options.affiliateBadge
  const globalScrollPaddingTop = usePage(pageSelectors.getGlobalScrollPaddingTop)

  return (
    <React.Fragment>
      <GlobalStyles scrollPaddingTop={globalScrollPaddingTop} />
      <Helmet>
        <link rel="canonical" href={currentUrl} />
        {/**
         * NOTE: The "maximum-scale=1" fixes auto zoom for mobile Safari
         * https://systeme-team.slack.com/archives/C020MV26SBH/p1698829081911889?thread_ts=1697728533.366679&cid=C020MV26SBH
         * */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        {!seo.index && <meta name="robots" content="noindex" />}
      </Helmet>
      <PreviewBanner />
      <Notification />
      <ThemeProvider
        theme={{
          phone: getMediaQueryByPageType(pageType).phone,
          desktop: getMediaQueryByPageType(pageType).desktop,
          isMobile,
        }}
      >
        <PageType>{createEntityElement(rootEntity)}</PageType>
      </ThemeProvider>
      {showAffiliateBadge && <AffiliateBadge />}
    </React.Fragment>
  )
}

Page.propTypes = {
  createPage: PropTypes.func.isRequired,
  createEntityElement: PropTypes.func.isRequired,
}

export default Page
